const others = [
    {name:'Balcón'},
    {name:'Cocina'},
    {name:'Cocina/Comedor'},
    {name:'Comedor'},
    {name:'Depósito'},
    {name:'Estudio'},
    {name:'Galería'},
    {name:'Jardín'},
    {name:'Lavadero'},
    {name:'Living'},
    {name:'Living/Comedor'},
    {name:'Local Comercial'},
    {name:'Patio'},
    {name:'Pileta'},
    {name:'Pileta Climatizada'},
    {name:'Quincho'},
    {name:'Recepción'},
    {name:'Sala de Juegos'},
    {name:'Solarium'},
    {name:'Terraza'},
    {name:'Vestidor'},
]

export default others