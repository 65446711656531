export const propertyTypes = [
    "Cabaña",
    "Campo",
    "Casa",
    "Cochera",
    "Complejo",
    "Departamento",
    "Deposito",
    "Duplex",
    "Fondo de comercio",
    "Galpon",
    "Hotel",
    "Local",
    "Loteo",
    "Monoambiente",
    "Oficina",
    "Terreno"
]