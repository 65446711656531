const services = [
    {name:'Agua corriente'},
    {name:'Cloacas'},
    {name:'Club House'},
    {name:'Expensas'},
    {name:'Gas natural'},
    {name:'Luz'},
    {name:'Pavimento'},
    {name:'Seguridad'},
    {name:'Seguridad 24Hs'},
    {name:'Sin expensas'},
]

export default services